<template>
  <el-row :gutter='10'>
    <el-col :lg="12" :md="24" :sm="24" :xl="12" :xs="24" class="ul">
      <div class="dialogCard">
        <div class="subBox">
          <div class="title">用户访问</div>
          <div class="subTitle">更新时间：{{nowTime}}</div>
        </div>

        <el-row>
          <el-col :lg="12" :md="24" :sm="24" :xl="12" :xs="24" class="ul" v-for="(item, index) in pvuvdata" :key="index">
            <p class='smTitle'>{{item.name}}</p>
            <div v-if="loading">加载中...</div>
            <template v-else>
              <vab-count :decimals="item.countConfig.decimals" :duration="item.countConfig.duration" :end-val="item.countConfig.endVal" :prefix="item.countConfig.prefix" :separator="item.countConfig.separator" :start-val="item.countConfig.startVal"
                style="font-size:26px;color:#1D2129;font-weight:bold;" />
              <span style="font-size:16px;color:#4e5969">{{item.countConfig.suffix}}</span>
            </template>
          </el-col>
        </el-row>
      </div>
    </el-col>
    <el-col :lg="12" :md="24" :sm="24" :xl="12" :xs="24" class="ul">
      <div class="dialogCard">
        <div class="subBox">
          <div class="title">{{ meetingType == 'EXHIBITION' ? `注册签到` : `用户注册`}}</div>
          <div class="subTitle">更新时间：{{nowTime}}</div>
        </div>

        <el-row>
          <el-col :lg="12" :md="24" :sm="24" :xl="12" :xs="24" class="ul" v-for="(item, index) in signData" :key="index">
            <template v-if="!item.hide">
              <p class='smTitle'>{{item.name}} <span v-if="item.countConfig.showPre" style="font-size: 12px;color: #00C4C9;margin-left: 5px;">占比{{item.countConfig.percent}}%</span></p>
              <div v-if="loading">加载中...</div>
              <template v-else>
                <vab-count :decimals="item.countConfig.decimals" :duration="item.countConfig.duration" :end-val="item.countConfig.endVal" :prefix="item.countConfig.prefix" :separator="item.countConfig.separator" :start-val="item.countConfig.startVal"
                  style="font-size:26px;color:#1D2129;font-weight:bold;" />
                <span style="font-size:16px;color:#4e5969">{{item.countConfig.suffix}}</span>
              </template>
            </template>
          </el-col>
        </el-row>
      </div>
    </el-col>

  </el-row>

</template>

<script>
import { defineComponent, reactive, toRefs, watch } from 'vue'
import _ from 'lodash'
import VabChart from '@/extra/VabChart'
import VabCount from '@/extra/VabCount'

export default defineComponent({
  components: {
    VabChart,
    VabCount,
  },
  props: {
    data: {
      type: Object,
      default: {},
    },
    nowTime: {
      type: String,
      default: '',
    },
    meetingType: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    watch(
      () => props.data,
      (newVal, oldVal) => {
        state.pvuvdata[0].countConfig.endVal = newVal.userVisitNum
        state.pvuvdata[1].countConfig.endVal = newVal.pageVisitTimes
        state.signData[0].countConfig.endVal = newVal.meetingSignUpNum
        // state.signData[1].countConfig.endVal = newVal.meetingApprovedNum
        // state.signData[1].countConfig.percent =
        //   newVal.meetingApprovedNumProportion
        state.signData[1].countConfig.endVal = newVal.meetingSignInNum
        state.signData[1].countConfig.percent =
          newVal.meetingSignInNumProportion

        if (props.meetingType != 'EXHIBITION') {
          state.signData[1].hide = true
        }
        setTimeout(() => {
          state.loading = false
        }, 100)
      }
    )
    const state = reactive({
      loading: true,
      pvuvdata: [
        {
          countConfig: {
            startVal: 0,
            endVal: 0,
            decimals: 0,
            prefix: '',
            suffix: '人',
            separator: ',',
            duration: 3000,
          },
          name: '累计访问人数',
          colCount: 8,
        },
        {
          countConfig: {
            startVal: 0,
            endVal: 0,
            decimals: 0,
            prefix: '',
            suffix: '次',
            separator: ',',
            duration: 3000,
          },
          name: '累计访问页面数',
          colCount: 8,
        },
      ],
      signData: [
        {
          countConfig: {
            startVal: 0,
            endVal: 0,
            decimals: 0,
            prefix: '',
            suffix: '人',
            separator: ',',
            duration: 3000,
          },
          name: '累计注册',
          colCount: 8,
          hide: false,
        },
        // {
        //   countConfig: {
        //     startVal: 0,
        //     endVal: 0,
        //     decimals: 0,
        //     prefix: '',
        //     suffix: '人',
        //     separator: ',',
        //     duration: 3000,
        //     percent: 0,
        //     showPre: true,
        //   },
        //   name: '审核通过',
        //   colCount: 8,
        // },
        {
          countConfig: {
            startVal: 0,
            endVal: 0,
            decimals: 0,
            prefix: '',
            suffix: '人',
            separator: ',',
            duration: 3000,
            percent: 0,
            showPre: true,
          },
          name: '已签到',
          colCount: 8,
          hide: false,
        },
      ],
    })
    return {
      ...toRefs(state),
    }
  },
})
</script>


<style lang="scss" scoped>
.dialogCard {
  width: 100%;
  min-height: 174px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 10px;
  .subBox {
    .title {
      font-weight: 500;
      font-size: 16px;
      color: #1d2129;
      margin-bottom: 4px;
    }
    .subTitle {
      font-size: 12px;
      color: #4e5969;
    }
  }

  .ul {
    margin-top: 15px;
    .smTitle {
      font-size: 16px;
      color: #1d2129;
      display: flex;
      align-items: center;
    }
  }
}
</style>